<template>
  <div id="roleList">
    <div class="list-title">角色列表</div>
    <div class="search-box">
      <div class="search-box-left">
        <el-input size="small" class="input-search" placeholder="搜索角色名称" v-model="listParams.name">
             <i slot="suffix" class="el-input__icon el-icon-search" @click="getRoleList"></i>
        </el-input>
        <div class="label1">角色状态</div>
        <el-select size="small" v-model="listParams.enable" @change="getRoleList" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-box-right">
        <el-button size="small" type="primary" @click="open" icon="el-icon-plus">新增角色</el-button>
      </div>
    </div>
    <div class="list-box">
      <el-table :data="roleList" style="width: 100%">
        <el-table-column prop="name" label="角色名称">
          <template slot-scope="scope">
            <span style="color:#0064ff;cursor: pointer;" @click="toSet(scope.row)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userCount" label="角色人数">
          <template slot-scope="scope">
            <span style="color:#0064ff;cursor: pointer;" @click="openStaff(scope.row)">{{ scope.row.userCount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="角色状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.enable" @change="changeStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
           <template slot-scope="scope">
            <el-popover
              placement="top-start"
              v-model="scope.row.isCopy"
              width="200"
              trigger="click">
              <div class="popcontent"><span>是否复制{{scope.row.name}}</span></div>
              <div style="text-align: right; margin: 10px">
                <el-button size="mini" type="text" @click="scope.row.isCopy = false">取消</el-button>
                <el-button type="primary" size="mini" @click="copy(scope.row)">确定</el-button>
              </div>
              <el-button type="text" style="margin-left: 10px;" slot="reference">复制</el-button>
            </el-popover>
            <el-button type="text" @click="edit(scope.row)" v-if="scope.row.isDefault != '000'">编辑</el-button>
            <el-popover
              placement="top-start"
              v-model="scope.row.isDelete"
              width="200"
              trigger="click">
              <div class="popcontent"><span>是否删除{{scope.row.name}}</span></div>
              <div style="text-align: right; margin: 10px">
                <el-button size="mini" type="text" @click="scope.row.isDelete = false">取消</el-button>
                <el-button type="primary" size="mini" @click="deleteRole(scope.row)">确定</el-button>
              </div>
              <el-button type="text" v-if="scope.row.isDefault != '000'" slot="reference">删除</el-button>
            </el-popover>
           </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="total > 0" class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="listParams.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="listParams.pageSize"
        layout="sizes, prev, pager, next" :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="type === 'add' ? '新增角色':'编辑角色'" :visible.sync="dialogVisible" width="30%">
      <div class="dialog-body">
        <div class="world"><span style="color:red;">*</span><span style="width:70px">角色名称</span></div>
        <el-input class="input-search" placeholder="" v-model="addParams.name">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="toEditAuthority">保存去编辑权限</el-button>
        <el-button type="primary" @click="saveRole">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userRoleService } from "@/api";
import RoleMixins from "./roleMixins";

 export default {
  name: "Role",
  mixins: [RoleMixins],
  data() {
    return {
      selectItem: {},
      type: "add",
      dialogVisible: false,
      addParams: {
        name: "",
        id: "",
        roleLink: 1
      },
      total: 0,
      pageSize: 10,
      roleList: [],
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "0",
          label: "启用"
        },
        {
          value: "1",
          label: "停用"
        }
      ],
      listParams: {
        name: "",
        enable: "",
        roleLink: 1,
        pageSize: 10,
        pageNo: 1
      }
    };
  },
  methods: {
    async changeStatus(item) {
      const res = await userRoleService.changeRoleStatus({
          id: item.id,
          enable: item.enable ? "0" : "1"
        });
        this.$message.success("修改角色状态成功");
        this.getRoleList();
    },
    edit(item) {
      this.dialogVisible = true;
      this.type = "edit";
      this.selectItem = item;
      this.addParams = {
        name: item.name,
        id: item.id,
        roleLink: 1
      };
    },
    openStaff(item) {
      this.$emit("open-staff", item);
    },
    open() {
      this.type = "add";
      this.addParams = {
        name: "",
        roleLink: 1
      };
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.listParams.pageSize = val;
      this.getRoleList();
    },
    handleCurrentChange(val) {
      this.listParams.pageNo = val;
      this.getRoleList();
    },
    toSet(item) {
      this.$router.push({
        path: "/setRole",
        query: {
          id: item.id,
          name: item.name,
          type: "role"
        }
      });
    },
    async toEditAuthority() {
      await this.saveRole(false);
      this.$router.push({
        path: "/setRole",
        query: {
          id: this.addParams.id,
          name: this.addParams.name,
          type: "role"
        }
      });
    }
  },
  mounted() {
    this.getRoleList();
  }
 };
</script>
