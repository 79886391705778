var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "roleList" } },
    [
      _c("div", { staticClass: "list-title" }, [_vm._v("角色列表")]),
      _c("div", { staticClass: "search-box" }, [
        _c(
          "div",
          { staticClass: "search-box-left" },
          [
            _c(
              "el-input",
              {
                staticClass: "input-search",
                attrs: { size: "small", placeholder: "搜索角色名称" },
                model: {
                  value: _vm.listParams.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listParams, "name", $$v)
                  },
                  expression: "listParams.name",
                },
              },
              [
                _c("i", {
                  staticClass: "el-input__icon el-icon-search",
                  attrs: { slot: "suffix" },
                  on: { click: _vm.getRoleList },
                  slot: "suffix",
                }),
              ]
            ),
            _c("div", { staticClass: "label1" }, [_vm._v("角色状态")]),
            _c(
              "el-select",
              {
                attrs: { size: "small", placeholder: "请选择" },
                on: { change: _vm.getRoleList },
                model: {
                  value: _vm.listParams.enable,
                  callback: function ($$v) {
                    _vm.$set(_vm.listParams, "enable", $$v)
                  },
                  expression: "listParams.enable",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-box-right" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.open },
              },
              [_vm._v("新增角色")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "list-box" },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.roleList } },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "角色名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0064ff",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toSet(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.name))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "userCount", label: "角色人数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0064ff",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openStaff(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.userCount))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "enable", label: "角色状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          on: {
                            change: function ($event) {
                              return _vm.changeStatus(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.enable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enable", $$v)
                            },
                            expression: "scope.row.enable",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              width: "200",
                              trigger: "click",
                            },
                            model: {
                              value: scope.row.isCopy,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isCopy", $$v)
                              },
                              expression: "scope.row.isCopy",
                            },
                          },
                          [
                            _c("div", { staticClass: "popcontent" }, [
                              _c("span", [
                                _vm._v("是否复制" + _vm._s(scope.row.name)),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  margin: "10px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        scope.row.isCopy = false
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copy(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v("复制")]
                            ),
                          ],
                          1
                        ),
                        scope.row.isDefault != "000"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              width: "200",
                              trigger: "click",
                            },
                            model: {
                              value: scope.row.isDelete,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isDelete", $$v)
                              },
                              expression: "scope.row.isDelete",
                            },
                          },
                          [
                            _c("div", { staticClass: "popcontent" }, [
                              _c("span", [
                                _vm._v("是否删除" + _vm._s(scope.row.name)),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  margin: "10px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        scope.row.isDelete = false
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRole(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            ),
                            scope.row.isDefault != "000"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.total > 0
            ? _c("el-pagination", {
                staticClass: "pagination",
                attrs: {
                  "current-page": _vm.listParams.pageNo,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.listParams.pageSize,
                  layout: "sizes, prev, pager, next",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.listParams, "pageNo", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.listParams, "pageNo", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type === "add" ? "新增角色" : "编辑角色",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-body" },
            [
              _c("div", { staticClass: "world" }, [
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                _c("span", { staticStyle: { width: "70px" } }, [
                  _vm._v("角色名称"),
                ]),
              ]),
              _c("el-input", {
                staticClass: "input-search",
                attrs: { placeholder: "" },
                model: {
                  value: _vm.addParams.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.addParams, "name", $$v)
                  },
                  expression: "addParams.name",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.toEditAuthority },
                },
                [_vm._v("保存去编辑权限")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveRole } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }