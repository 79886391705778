import { userRoleService } from "@/api";

export default {
  data() {
    return {
    };
  },
  methods: {
    async saveRole(refresh = true) {
      const res = this.type === "add" ? await userRoleService.addRoleList(this.addParams) : await userRoleService.editRoleList(this.addParams);
      this.dialogVisible = false;
      this.$message.success(`${this.type === "add" ? "新增" : "编辑"}角色成功}`);
      refresh && this.getRoleList();
    },
    async deleteRole(item) {
      const res = await userRoleService.deleteRoleList({
        id: item.id
      });
      this.$message.success("删除角色成功");
      this.getRoleList();
    },
    async getRoleList() {
      const res = await userRoleService.getRoleList(this.listParams);

      if (res) {
        this.roleList = res.records.map((it) => ({
          ...it,
          enable: it.enable === "0",
          isDelete: false,
          isCopy: false
        }));

        this.total = res.total;
      }
    },
    async copy(item) {
      const res = await userRoleService.copyRole({
        copyRoleId: item.id
      });
      this.$message.success("复制角色成功");
      this.getRoleList();
    }
  }
};
