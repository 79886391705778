<template>
  <div id="role">
    <el-tabs v-model="activeName">
      <el-tab-pane label="角色" name="role">
        <role-list v-if="activeName === 'role'" @open-staff="openStaff" ref="role" ></role-list>
      </el-tab-pane>
      <el-tab-pane label="员工" name="staff">
        <staff-list v-if="activeName === 'staff'" :cur-role="curRole"  ref="staffList"
          @open-add-role="openAddRole" @open-edit-role="openEditRole"></staff-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import roleList from "./components/roleList.vue";
import staffList from "./components/staffList.vue";

 export default {
  name: "Role",
  components: { roleList, staffList },
  data() {
    return {
      activeName: "role",
        curRole: null
    };
  },
  methods: {
      openStaff(itemRole) {
        this.activeName = "staff";
        this.curRole = itemRole;
      },
      openAddRole(falg) {
        this.activeName = "role";
        this.$nextTick(() => {
          falg && this.$refs.role.open();
        });
      },
      openEditRole(item) {
        this.activeName = "role";
        this.$nextTick(() => {
          item && this.$refs.role.edit(item);
        });
      }
    },
    mounted() {
      const type = this.$route.query.type || "role";
      if (type === "staff") {
          this.activeName = "staff";
              this.currentTabComponent = "staff-list";
      } else {
          this.activeName = "role";
      }
    }
 };
</script>
<style lang="scss">
  #role {
     background-color: #fff;
      width: 100%;
      height: 100%;

      .to{
            cursor: pointer;
        }
        .role-title {
            padding: 10px 20px;
            border-bottom: 1px solid #eee;
            font-size: 18px;
            color: #333;
            background-color: #fff;
            font-weight: bold;
        }

        #roleList {
            height: 100%;
            border-radius: 6px;
            background-color: #fff;
            padding: 15px 10px;

        }

        .list-title {
            font-size: 14px;
            margin-bottom: 15px;
            color: #333;
        }
        .el-table thead th.el-table__cell.is-leaf{
            background-color: rgba(242, 242, 242, 0.4470588235294118);
            font-size: 14px;
            color: #999;
            font-weight: 400;
        }
        .el-table .cell{
            font-size: 14px;
            color: #333;
        }
        .search-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }

        .search-box-left {
            display: flex;
            align-items: center;
        }

        .label1 {
            width: 100px;
            margin-left: 15px;
            font-size: 14px;
        }

        .input-search {
            margin-right: 10px;
        }

        .pagination {
            margin-top: 10px;
        }
        .el-tabs{
            height: 100%;
            background-color: #ecf1f8;
        }
        .el-tabs__content {
            background-color: #ecf1f8;
            height: calc(100% - 40px);

        }
        .el-tabs__item{
            width: 100px;
            text-align: center;
        }
        .el-tabs__header {
            padding: 0 20px;
            background-color: #fff;
            color: #333;
            font-size: 16px;
        }
        .el-tabs__active-bar{
            width: 80px!important;
        }
        .el-tab-pane {
          margin-top: 10px;
            height: 100%;
        }
        .el-tabs__nav-wrap::after{
            background-color: transparent!important;
        }
        .dialog-body {
            display: flex;
            align-items: center;
            padding: 30px 40px;
        }

        .world {
            display: flex;
            align-items: center;
        }

        #staffList {
            display: flex;
            height: 100%;
        }

        .staffList-left {
            width: 220px;
            border-radius: 6px;
            background-color: #fff;
            margin-right: 5px;
            padding: 20px;
            height: 100%;
            overflow: auto;
        }

        .staffList-right {
            padding: 10px 20px;
            flex: 1;
            border-radius: 6px;
            background-color: #fff;
        }

        .role-list {
            width: 100%;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 4px;
            margin-bottom: 10px;
            position: relative;
            margin-top: 5px;
            font-size: 14px;
            cursor: pointer;

        }

        .role-list .title {
            padding: 0 20px;
            font-size: 14px;
            color: #333;
            white-space: nowrap;
            /* 超出的空白区域不换行 */
            overflow: hidden;
            /* 超出隐藏 */
            text-overflow: ellipsis;
            /* 文本超出显示省略号 */
        }

        .role-list:hover {
            background-color: #bbbbbb;
        }

        .isActive {
            background-color: #bbbbbb;
        }

        .more {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: rotate(90deg) translate(-50%);
            cursor: pointer;
        }
        .el-popover {
            padding: 0;
            width: 80px;
            min-width: 80px;
        }

        .role-btn-opt {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            position: sticky;
            bottom: 0;
        }

        .wl-transfer .transfer-title {
            margin: 0;
        }

        .transfer-title .el-checkbox {
            display: none;
        }

        .popcontent {
            margin: 10px;
            text-align: center;
        }

        .transfer-left .filter-tree {
            display: none;
        }

        .transfer-left .input-search {
            margin-bottom: 10px;
        }
        .el-dialog__body{
            padding: 10px 20px;
            border: 1px solid #eee;
        }
  }
  .role-list-btn {
      font-size: 12px;
      padding: 10px 0;
      text-align: center;
      cursor: pointer;
      border-bottom: 1px solid #eee;
    }

    .role-list-btn:last-of-type {
      border: none;
    }

    .role-list-btn:hover {
      color: #0064ff;
    }

    .el-popover {
      padding: 0;
      width: 80px;
      min-width: 80px;
    }
</style>
