var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "role" } },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "角色", name: "role" } },
            [
              _vm.activeName === "role"
                ? _c("role-list", {
                    ref: "role",
                    on: { "open-staff": _vm.openStaff },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "员工", name: "staff" } },
            [
              _vm.activeName === "staff"
                ? _c("staff-list", {
                    ref: "staffList",
                    attrs: { "cur-role": _vm.curRole },
                    on: {
                      "open-add-role": _vm.openAddRole,
                      "open-edit-role": _vm.openEditRole,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }