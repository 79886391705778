<template>
 <div id="staffList">
    <div class="staffList-left">
      <el-input size="small" class="input-search" placeholder="搜索角色名称"
        v-model="listParams.name">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="getRoleList"></i>
      </el-input>
      <div class="role-list" @click="selectRole(item)" v-for="(item,index) in roleList"
        :class="{isActive:item.name === selectRoleInfo.name}" :key="index">
        <div class="title" :title="item.name">{{item.name}}</div>
        <el-popover placement="bottom-start" trigger="hover">
          <div class="role-list-btn" @click="toSet(item)">查看</div>
          <div class="role-list-btn"  v-if="item.isDefault != '000'"  @click="editRole(item)">编辑</div>
          <div class="role-list-btn" @click="copy(item)">复制</div>
          <div class="role-list-btn"  v-if="item.isDefault != '000'"  @click="deleteListRole(item)">删除</div>
          <i slot="reference" class="el-icon-more more"></i>
        </el-popover>
      </div>
      <div class="role-btn-opt">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addRole(true)">新增角色</el-button>
        <el-button size="small" type="primary" @click="addRole(false)">管理角色</el-button>
      </div>
    </div>
    <div class="staffList-right">
      <div class="list-title">已添加员工</div>
      <div class="search-box">
        <div class="search-box-left">
          <el-input size="small" class="input-search" placeholder="搜索员工"
            v-model="staffParams.name">
             <i slot="suffix" class="el-input__icon el-icon-search" @click="getStaffList"></i>
          </el-input>
          <span class="label1">员工状态</span>
          <el-select size="small" v-model="listParams.status"  @change="getStaffList" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button size="small" type="primary" @click="addStaff" icon="el-icon-plus">新增员工</el-button>
          <el-button size="small" type="primary" @click="deldetUser">移除员工</el-button>
        </div>
      </div>
      <div class="list-box">
        <el-table ref="multipleTable" :data="staffList" tooltip-effect="dark" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column prop="name" label="">
            <template slot="header">
              <span>已选 {{selectedCount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="enterpriseName" label="所属公司">
          </el-table-column>
          <el-table-column prop="deptName" label="部门">
          </el-table-column>
          <el-table-column prop="mobileNo" label="手机">
          </el-table-column>
          <el-table-column prop="enable" label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.enable === '000' ? '正常' : '禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
           <template slot-scope="scope">
               <el-popover
                placement="top-start"
                v-model="scope.row.isDelete"
                width="200"
                trigger="click">
                <div class="popcontent"><span>是否移除{{scope.row.name}}</span></div>
                <div style="text-align: right; margin: 10px">
                  <el-button size="mini" type="text" @click="scope.row.isDelete = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="deldetUser(scope.row)">确定</el-button>
                </div>
                <el-button type="text" slot="reference">移除</el-button>
              </el-popover>
           </template>
          </el-table-column>
        </el-table>
        <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="listParams.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="listParams.pageSize"
          layout="sizes, prev, pager, next" :total="total">
        </el-pagination> -->
      </div>
    </div>
    <el-dialog title="新增员工" :visible.sync="dialogVisible" width="60%">
      <tree-transfer :title='[]' :from_data='fromData' :to_data='toData' ref="transferTree"
        :default-props="{label:'name',children:'children',isLeaf:'leaf'}" @add-btn='add' :lazy="true"
        :lazy-fn="loadNode"  placeholder="请输入姓名,工号或岗位" @remove-btn='remove' :mode='mode' height='540px' filter>
        <template slot="from">
           <el-input
            size="small"
            class="input-search"
            placeholder="请输入内容"
            v-model="name">
            <i @click='searchTree' slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </template>
      </tree-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userRoleService } from "@/api";
import RoleMixins from "./roleMixins";

const treeTransfer = window.default;
 export default {
  name: "Role",
  mixins: [RoleMixins],
  components: { treeTransfer },
  props: {
    curRole: Object
  },
  data() {
    return {
       staffParams: {
            name: "",
            enable: ""
        },
        name: "",
        soureFrom: [],
        mode: "transfer",
        title: "人员",
        fromData: [],
        toData: [],
        staffListAll: [],
        selectRoleInfo: {},
        isChecked: false, // 表头复选框状态
        isIndeterminate: false, // 表头复选框半选状态
        dialogVisible: false,
        addParams: {
          name: ""
        },
        selectStaff: [],
        total: 100,
        pageSize: 10,
        roleList: [],
        staffList: [],
        options: [
          {
            value: "",
            label: "全部"
          },
          {
            value: "000",
            label: "正常"
          },
          {
            value: "001",
            label: "禁用"
          }
        ],
        listParams: {
          name: "",
          status: "",
          pageSize: 1000,
          pageNo: 1
        }
      };
  },
  computed: {
      selectedCount() {
        return this.selectStaff.length;
      }
    },
    methods: {
      deleteListRole(item) {
        this.$confirm("是否删除该角色?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.deleteRole(item);
          })
          .catch(() => {});
      },
      copyList(item) {
        this.$confirm("是否复制该角色?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.copy(item);
          })
          .catch(() => {});
      },
      toSet(item) {
        this.$router.push({
        path: "/setRole",
        query: {
          id: item.id,
          name: item.name,
          type: "staff"
        }
      });
      },
      async deldetUser(item = null) {
        if (!item.accountId && !this.selectStaff.length) return;
        const res = await userRoleService.deleteRoleAccount({
            roleId: this.selectRoleInfo.id,
            accountIds: item.accountId
              ? JSON.stringify([item.accountId])
              : JSON.stringify(this.selectStaff.map((it) => it.accountId))
          });
          this.$message.success("移除员工成功");
          this.getStaffList();
      },
      async addUser() {
        if (!this.selectRoleInfo.id) {
          this.$message.error("请先选择角色");
          return;
        }
        const data = this.filterAccountIdDataRecursive(this.toData);
        const res = await userRoleService.addRoleAccount({
          data: JSON.stringify(data),
          roleId: this.selectRoleInfo.id
        });
        this.dialogVisible = false;
        this.$message.success("新增员工成功");
        this.getStaffList();
      },
      async getStaffList() {
        const res = await userRoleService.getRoleAccount({
            roleId: this.selectRoleInfo.id,
            ...this.staffParams
          });
        this.staffList = [];
        if (res) {
          this.staffList = res.records.map((it) => ({
              ...it,
              isDelete: false
            }));
          this.total = res.total;
        }
      },
      async loadNode(node, resolve) {
        console.log(node);
        if (node.id !== 0) {
            if (node.data.hasChildren === "000") {
                  await this.getTreeList(node.data.id, resolve);
            } else {
                  resolve([]);
            }
        }
      },
      // 递归筛选 人员
      filterAccountIdDataRecursive(dataArray) {
        const filteredArray = [];
        function recursiveFilter(item) {
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty("accountId")) {
            filteredArray.push(item);
          }
          if (item.children && item.children.length > 0) {
            item.children.forEach((child) => recursiveFilter(child));
          }
        }

        dataArray.forEach((element) => recursiveFilter(element));
        console.log(filteredArray);
        return filteredArray;
      },
      setChildrenRecursive(data, id, arr) {
        data.forEach((item) => {
          if (item.id === id) {
            this.$set(item, "children", arr);
          } else if (item.children && item.children.length > 0) {
            // 如果当前节点有子节点，递归调用处理子节点
            this.setChildrenRecursive(item.children, id, arr);
          }
        });
      },
      async searchTree() {
        const res = await userRoleService.getDeptTree({
            id: 0,
            name: this.name,
            submitToken: "774ce947-370b-41e8-80fa-c21b9a4e319c"
          });
        if (res) {
          const arr = res.map((it) => {
            if (it.hasChildren === "000") {
              return {
                ...it,
                pid: 0
              };
            }
              return {
                leaf: true,
                ...it,
                pid: 0,
                disabled: it.type !== "003"
              };
          });
          this.fromData = [];
          this.fromData = arr;
          // 储存 源数据
          this.soureFrom = JSON.parse(JSON.stringify(this.fromData));
          console.log(this.soureFrom);
        }
      },
      async getTreeList(id, callback) {
        const res = await userRoleService.getDeptTree({
            id,
            submitToken: "774ce947-370b-41e8-80fa-c21b9a4e319c"
          });
        if (res) {
          const arr = res.map((it) => {
            if (it.hasChildren === "000") {
              return {
                ...it,
                pid: id
              };
            }
              return {
                leaf: true,
                ...it,
                pid: id,
                disabled: it.type !== "003"
              };
          });
          callback && callback(arr);
          if (this.fromData.length) {
            this.setChildrenRecursive(this.fromData, id, arr);
          } else {
            this.fromData = arr;
          }
          // 储存 源数据
          this.soureFrom = JSON.parse(JSON.stringify(this.fromData));
          console.log(this.soureFrom);
        }
      },
      flattenAndFilter(data) {
        const result = [];
        function traverse(node) {
          if (node.type === "003") {
            result.push(node);
          }
          if (node.children && node.children.length > 0) {
            node.children.forEach(traverse);
          }
        }
        if (data && data.length > 0) {
          data.forEach(traverse);
        }
        return result;
      },
      add(fromData, toData, obj) {
        if (this.filterAccountIdDataRecursive(toData).length === 0) {
          this.toData = [];
          if (!this.fromData.length) {
            this.fromData = this.soureFrom;
          }
          this.$message.error("请选择数据");
          return;
        }
        // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
        // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
        console.log("fromData:", fromData);
        console.log("toData:", toData);
        console.log("obj:", obj);
        // this.toData = this.flattenAndFilter(toData)
        // toData = []
      },

      // 监听穿梭框组件移除
      remove(fromData, toData, obj) {
        // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
        // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
        console.log("fromData:", fromData);
        console.log("toData:", toData);
        console.log("obj:", obj);
      },
      selectRole(item) {
        this.selectRoleInfo = item;
        this.getStaffList();
      },
      addRole(falg) {
        this.$emit("open-add-role", falg);
      },
      editRole(item) {
        this.$emit("open-edit-role", item);
      },
      handleSelectionChange(val) {
        this.selectStaff = val;
      },
      handleSizeChange(val) {
        this.listParams.pageSize = val;
      },
      handleCurrentChange(val) {
        this.listParams.page = val;
      },
      async addStaff() {
        this.dialogVisible = true;
        this.fromData = [];
        this.toData = [];
        await this.getTreeList(0);
      }
    },
    mounted() {
      this.getRoleList();
      if (this.curRole && this.curRole.name) {
        this.selectRoleInfo = this.curRole;
        this.getStaffList();
      }
    }
 };
</script>
