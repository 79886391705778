var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "staffList" } },
    [
      _c(
        "div",
        { staticClass: "staffList-left" },
        [
          _c(
            "el-input",
            {
              staticClass: "input-search",
              attrs: { size: "small", placeholder: "搜索角色名称" },
              model: {
                value: _vm.listParams.name,
                callback: function ($$v) {
                  _vm.$set(_vm.listParams, "name", $$v)
                },
                expression: "listParams.name",
              },
            },
            [
              _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "suffix" },
                on: { click: _vm.getRoleList },
                slot: "suffix",
              }),
            ]
          ),
          _vm._l(_vm.roleList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "role-list",
                class: { isActive: item.name === _vm.selectRoleInfo.name },
                on: {
                  click: function ($event) {
                    return _vm.selectRole(item)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "title", attrs: { title: item.name } },
                  [_vm._v(_vm._s(item.name))]
                ),
                _c(
                  "el-popover",
                  { attrs: { placement: "bottom-start", trigger: "hover" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "role-list-btn",
                        on: {
                          click: function ($event) {
                            return _vm.toSet(item)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    item.isDefault != "000"
                      ? _c(
                          "div",
                          {
                            staticClass: "role-list-btn",
                            on: {
                              click: function ($event) {
                                return _vm.editRole(item)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "role-list-btn",
                        on: {
                          click: function ($event) {
                            return _vm.copy(item)
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                    item.isDefault != "000"
                      ? _c(
                          "div",
                          {
                            staticClass: "role-list-btn",
                            on: {
                              click: function ($event) {
                                return _vm.deleteListRole(item)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon-more more",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ]
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "role-btn-opt" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addRole(true)
                    },
                  },
                },
                [_vm._v("新增角色")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addRole(false)
                    },
                  },
                },
                [_vm._v("管理角色")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("div", { staticClass: "staffList-right" }, [
        _c("div", { staticClass: "list-title" }, [_vm._v("已添加员工")]),
        _c("div", { staticClass: "search-box" }, [
          _c(
            "div",
            { staticClass: "search-box-left" },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-search",
                  attrs: { size: "small", placeholder: "搜索员工" },
                  model: {
                    value: _vm.staffParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.staffParams, "name", $$v)
                    },
                    expression: "staffParams.name",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    attrs: { slot: "suffix" },
                    on: { click: _vm.getStaffList },
                    slot: "suffix",
                  }),
                ]
              ),
              _c("span", { staticClass: "label1" }, [_vm._v("员工状态")]),
              _c(
                "el-select",
                {
                  attrs: { size: "small", placeholder: "请选择" },
                  on: { change: _vm.getStaffList },
                  model: {
                    value: _vm.listParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listParams, "status", $$v)
                    },
                    expression: "listParams.status",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.addStaff },
                },
                [_vm._v("新增员工")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.deldetUser },
                },
                [_vm._v("移除员工")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "list-box" },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.staffList, "tooltip-effect": "dark" },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", { attrs: { type: "selection" } }),
                _c(
                  "el-table-column",
                  { attrs: { prop: "name", label: "" } },
                  [
                    _c("template", { slot: "header" }, [
                      _c("span", [_vm._v("已选 " + _vm._s(_vm.selectedCount))]),
                    ]),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { prop: "enterpriseName", label: "所属公司" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "deptName", label: "部门" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "mobileNo", label: "手机" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "enable", label: "状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.enable === "000" ? "正常" : "禁用"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                width: "200",
                                trigger: "click",
                              },
                              model: {
                                value: scope.row.isDelete,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isDelete", $$v)
                                },
                                expression: "scope.row.isDelete",
                              },
                            },
                            [
                              _c("div", { staticClass: "popcontent" }, [
                                _c("span", [
                                  _vm._v("是否移除" + _vm._s(scope.row.name)),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    margin: "10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function ($event) {
                                          scope.row.isDelete = false
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deldetUser(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("移除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增员工",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "tree-transfer",
            {
              ref: "transferTree",
              attrs: {
                title: [],
                from_data: _vm.fromData,
                to_data: _vm.toData,
                "default-props": {
                  label: "name",
                  children: "children",
                  isLeaf: "leaf",
                },
                lazy: true,
                "lazy-fn": _vm.loadNode,
                placeholder: "请输入姓名,工号或岗位",
                mode: _vm.mode,
                height: "540px",
                filter: "",
              },
              on: { "add-btn": _vm.add, "remove-btn": _vm.remove },
            },
            [
              _c(
                "template",
                { slot: "from" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-search",
                      attrs: { size: "small", placeholder: "请输入内容" },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "suffix" },
                        on: { click: _vm.searchTree },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addUser } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }